import { ReactElement, useEffect, useRef, useState, ReactNode } from "react";

interface FirePopoverProp {
    children: ReactNode;
    content: ReactElement | null;
    hasContent?: boolean;
    popupClassName?: string;
    contentClassName?:string;
    trigger?: string;
}

export default function FirePopover({
  children,
  content,
  hasContent = true,
  popupClassName = '',
  contentClassName = 'bg-white text-black p-3',
  trigger = "click"
}: FirePopoverProp) {

  const [show, setShow] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement >(null);

  const handleMouseOver = () => {
      if (trigger === "hover") {
        setShow(true);
      };
  };

  const handleMouseLeft = () => {
      if (trigger === "hover") {
        setShow(false);
      };
  };

  useEffect(() => {
      function handleClickOutside(event: any) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setShow(false);
        }
      }

      if (show) {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
  }, [show, wrapperRef]);

  return (
    <div
        ref={wrapperRef}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseLeft}
        className={`${popupClassName} cursor-pointer w-max h-fit relative flex justify-center`}
    >
        <div
          onClick={
            () => setShow(!show)
          }
        >
          {children}
        </div>
        {hasContent && (
          <div         
            className={`
                ${contentClassName} 
                absolute h-max w-max top-[100%] z-50
                rounded shadow-[10px_30px_150px_rgba(46,38,92,0.25)]
                ${show ? 
                    'opacity-100 duration-300 ease-in-out visible' :
                    'opacity-0 duration-300 ease-in-out invisible' 
                } 
            `}
          >
            {content}
          </div>
        )}
    </div>
  );
};